import React from "react";
import { Helmet } from "react-helmet";

export const IndexJsonLd = () => {
  const mainjson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Adam Fard",
    alternateName: "Adam Fard UX Studio",
    legalName: "UX and UI agency",
    url: "https://adamfard.com/",
    logo:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/f836f/ux-design-agency-3.jpg",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    disambiguatingDescription:
      "Our UX design agency creates solutions to address product usability, dwindling conversion rates and the ever-present challenges of user-retention & engagement.",
    foundingDate: "2016",
    slogan: "Scale your startup through exceptional product experience",
    foundingLocation: [
      {
        "@type": "Place",
        geo: {
          "@type": "GeoCoordinates",
          latitude: "37.7577627",
          longitude: "-122.4726194"
        },
        name: "UX Design for Startups & Brands"
      }
    ],
    actionableFeedbackPolicy: "https://adamfard.com/contact-us",
    areaServed: ["Germany", "USA"],
    award: [
      "Top Empathetic 2020",
      "Best UX design in Berlin",
      "2020 Best User testing",
      "Top UX research",
      "Top UX Agency & B2B company",
      "Top Web Design Company",
      "Featured UX Studio"
    ],
    brand: [
      "UX designer",
      "User testing",
      "Competitive analysis",
      "Design App",
      "What is ux design",
      "What is agile",
      "Redesigning",
      "UX research",
      "Technology in education",
      "Information architecture",
      "Dashboard design",
      "UX writing",
      "Heuristic evaluation",
      "Organizing workshops",
      "How to app design",
      "Product idea",
      "Design process UX",
      "Lean UX",
      "Dashboards UI",
      "UI dashboard",
      "Design libraries",
      "Heuristics analysis",
      "Prototypes design",
      "UX strategy",
      "Information architecture UX",
      "UI UX design agency"
    ],
    knowsAbout: [
      "UX design for startups",
      "UX design agency",
      "UX design blog",
      "Edtech startups",
      "Agile design",
      "App design process",
      "App monetization",
      "App redesign",
      "Future of augmented reality",
      "Technology and education",
      "UX research methods",
      "Value of ux design",
      "How to choose brand colors",
      "Best chatbot",
      "Competitive market analysis",
      "What is user testing",
      "Contextual inquiry UX",
      "Customer experience strategy",
      "Customer journey template",
      "Dashboard ui design",
      "UX design system",
      "Design thinking process",
      "Design thinking workshop",
      "Design validation",
      "Design bias",
      "Design ops",
      "User experience testing",
      "UX best practices",
      "Edtech trends",
      "App designer education",
      "Design thinking empathy",
      "Enterprise software UX design",
      "Fintech App",
      "Fintech UX Design",
      "UX Gamification",
      "Goodfirms",
      "App development mistakes",
      "Heuristic evaluation",
      "Hire a UX Designer",
      "Product validation",
      "New business startup ideas",
      "User behavior",
      "Information architecture UX",
      "Lean UX design",
      "Usability Metrics",
      "Fintech trends",
      "Persuasive Design",
      "Pitch Decks",
      "Product prototype",
      "UX prototyping",
      "Quibi app review",
      "Racial discrimination in the workplace",
      "Remote team building",
      "Retention Rate",
      "Stakeholder interview questions",
      "Funding strategies for startups",
      "App stickiness",
      "Design style guide",
      "Trello review",
      "Understanding User Behavior",
      "Usability testing methods",
      "Usability testing questions",
      "Usability testing tools",
      "User research methods",
      "SaaS Monetization",
      "UX and SEO",
      "UX architect",
      "UX Audit",
      "What Does a UX Designer Do",
      "Onboarding UX checklist",
      "UX strategy",
      "UX testing",
      "UI UX tools",
      "UX workshop",
      "UX writing",
      "Design Validation",
      "Website Redesign",
      "Purpose of wireframes",
      "Business Development Manager",
      "Best UX Design Resources",
      "Customer Experience Strategy",
      "Heuristic evaluation checklist",
      "How to Launch a Digital Product",
      "Startup Pitch Deck",
      "Prototyping in UX",
      "Client questionnaire",
      "Usability Testing Checklist",
      "UX designer interview questions",
      "Create an educational App",
      "Fintech startups",
      "Free strategy session",
      "Marketing specialist",
      "Communications as a Service Platform",
      "City Ride App",
      "B2B payment platform",
      "App testing platform",
      "Morecast Weather App",
      "SaaS ux design",
      "UI ux designer job description",
      "UX Design Process",
      "UX Design Projects"
    ],
    founders: [
      {
        "@type": "Person",
        name: "Adam Fard",
        url: "https://www.linkedin.com/in/adamfard/"
      }
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: ["Neue Schönhauser Straße 3", "981 Mission St"],
      addressLocality: ["Berlin", "San Francisco"],
      addressRegion: ["BE", "CA"],
      postalCode: ["10178", "94103"],
      areaServed: ["Germany", "USA"]
    },
    contactPoint: {
      "@type": "ContactPoint",
      contactType: "custome suppost",
      email: "contact@adamfard.com",
      availableLanguage: "English and Germany"
    },
    department: "UX and UI design",
    employee: "2-10",
    sameAs: [
      "https://www.linkedin.com/company/adam-fard/",
      "https://twitter.com/adamfard_",
      "https://medium.com/@adam.fard/",
      "https://dribbble.com/adamfardstudio",
      "https://www.behance.net/AdamFardStudio/"
    ]
  };

  const websiteJson = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    about:
      "Our UX design agency creates solutions to address product usability, dwindling conversion rates and the ever-present challenges of user-retention & engagement. No matter whether you're set on your goal or need guidance, our agile UX studio is here to help. We are flexible enough to seamlessly integrate in your team’s processes.",
    url: "https://adamfard.com/",
    name: "Adam Fard UX Studio",
    alternateName: "Adam Fard UX Studio | UX Design for Startups & Brands",
    author: [{ "@type": "Person", name: ["Adam Fard"] }],
    keywords: [
      "UX designer",
      "User testing",
      "Competitive analysis",
      "Design App",
      "What is ux design",
      "What is agile",
      "Redesigning",
      "UX research",
      "Technology in education",
      "Information architecture",
      "Dashboard design",
      "UX writing",
      "Heuristic evaluation",
      "Organizing workshops",
      "How to app design",
      "Product idea",
      "Design process UX",
      "Lean UX",
      "Dashboards UI",
      "UI dashboard",
      "Design libraries",
      "Heuristics analysis",
      "Prototypes design",
      "UX strategy",
      "Information architecture UX",
      "UI UX design agency"
    ],
    mainEntity: "https://en.wikipedia.org/wiki/User_interface_design",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    disambiguatingDescription:
      "Improve your app's usability, retention rate, and conversion rate while achieving your business goals with UX Design Studio.",
    identifier: [
      "https://en.wikipedia.org/wiki/User_interface",
      "https://en.wikipedia.org/wiki/User_interface_design",
      "https://en.wikipedia.org/wiki/Usability_testing",
      "https://en.wikipedia.org/wiki/User_experience_design"
    ],
    image:
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
    mainEntityOfPage: "https://www.wikidata.org/wiki/Q11248500",
    potentialAction: {
      "@type": "SearchAction",
      target: "https://adamfard.com/s={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    sameAs: [
      "https://www.linkedin.com/company/adam-fard/",
      "https://twitter.com/adamfard_",
      "https://medium.com/@adam.fard/",
      "https://dribbble.com/adamfardstudio",
      "https://www.behance.net/AdamFardStudio/"
    ]
  };

  const webpageJson = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://adamfard.com/#Webpage",
    headline: "Adam Fard UX Studio | UX Design for Startups & Brands",
    url: "https://adamfard.com/",
    primaryImageOfPage: [
      "https://adamfard.com/static/c3ead8266a0ba7e8c3e2d7638a3b4941/58556/ux-design-agency-3.webp",
      "https://www.datocms-assets.com/16499/1604481816-thinkpricing-thumbnail-fintech-b.png",
      "https://www.datocms-assets.com/16499/1594154933-qolo-thumbnail-fintech.png"
    ],
    lastReviewed: "2021/02/10",
    relatedLink: ["https://en.wikipedia.org/wiki/User_interface_design"],
    significantLink: "https://adamfard.com/contact-us",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    image: "https://www.datocms-assets.com/16499/1578152525-dtappux.png",
    sameAs: ["https://en.wikipedia.org/wiki/User_interface"],
    about: [
      {
        "@type": "thing",
        name: "User interface design",
        sameAs: ["https://en.wikipedia.org/wiki/User_interface_design"]
      },
      {
        "@type": "thing",
        name: "Usability testing",
        sameAs: "https://en.wikipedia.org/wiki/Usability_testing"
      },
      {
        "@type": "thing",
        name: "User experience design",
        sameAs: "https://en.wikipedia.org/wiki/User_experience_design"
      }
    ]
  };

  const breadCrumbJson = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    numberOfitems: "1",
    description:
      "UX Design Studio: Improve your App’s Usability, Retention Rate, Conversion & realize your Business Goals.",
    disambiguatingDescription:
      "Improve your app's usability, retention rate, and conversion rate while achieving your business goals with UX Design Studio.",
    mainEntityOfPage: "https://adamfard.com/",
    image:
      "https://www.datocms-assets.com/16499/1573910711-morecastux.png?auto=format&dpr=0.77&w=680",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: { "@id": "https://adamfard.com/", name: "Adam Fard" }
      }
    ]
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(mainjson)}</script>
      <script type="application/ld+json">{JSON.stringify(websiteJson)}</script>
      <script type="application/ld+json">{JSON.stringify(webpageJson)}</script>
      <script type="application/ld+json">
        {JSON.stringify(breadCrumbJson)}
      </script>
    </Helmet>
  );
};
